.menu {
    font-size: 0.8rem;
    margin-right: 2rem;
}

.menu a {
    color: inherit;
    text-decoration: none;
}

.line {
    height: 2px;
    background-color: transparent;
    border-radius: 1px;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.menu .nav-decor {
    height: 5px;
    border-radius: 1;
    background: rgb(27, 27, 27);
    background: linear-gradient(90deg, rgba(27, 27, 27, 0) 0%, rgba(0, 0, 0, 1) 90%);
}

.menu .menu-item {
    cursor: pointer;
}

.menu .menu-item:hover:not(.active) i {
    transform: scale(1.3);
    padding-bottom: 0.3rem;
}

.menu i {
    font-size: 1.3rem;
}

.menu .active i {
    text-shadow: 0 0 1rem var(--accent-color);
    transform: scale(1.5);
    margin-bottom: 0.3rem;
    border-color: var(--accent-color) !important;
}

.menu .active div {
    color: var(--accent-color);
}

@media all and (max-width:600px) {
    .menu i {
        font-size: 1.5rem;
    }
}