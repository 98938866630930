.fa-phone {
    text-shadow: 0.2rem 0.2rem 0.5rem grey;
    animation: shake 100ms 2s 10 alternate-reverse forwards ease-in;
}

@keyframes shake {
    from {
        transform: rotate(40deg);
    }

    to {
        transform: rotate(20deg);
    }
}

.map {
    animation: slowlyVisible 2s 1 forwards ease-in;
    filter: saturate(1.5);
}

@keyframes slowlyVisible {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.75;
    }
}